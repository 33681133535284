<template>
  <div class="wrapper wrapper-content animated fadeInRight">
    <div class="row">
      <div class="col-lg-12">
        <div class="" id="ibox-content">
          <div id="vertical-timeline" class="vertical-container light-timeline">
            <div class="vertical-timeline-block">
              <div class="vertical-timeline-icon navy-bg">
                <i class="fa fa-file-text"></i>
              </div>
              <div class="vertical-timeline-content">
                <div class="row">
                  <div class="col-lg-12">
                    <div class="m-b-md">
                      <h2>{{ testCaseData.name }} Test Case</h2>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-6">
                    <dl class="row mb-0">
                      <div class="col-sm-4 text-sm-right"><dt>Id:</dt></div>
                      <div class="col-sm-8 text-sm-left">
                        <dd class="mb-1">{{ testCaseData.id }}</dd>
                      </div>
                    </dl>
                    <dl class="row mb-0">
                      <div class="col-sm-4 text-sm-right"><dt>Created:</dt></div>
                      <div class="col-sm-8 text-sm-left">
                        <dd class="mb-1">{{ testCaseData.createdDate }}</dd>
                      </div>
                    </dl>
                    <dl class="row mb-0">
                      <div class="col-sm-4 text-sm-right"><dt>Status:</dt></div>
                      <div class="col-sm-8 text-sm-left">
                        <dd class="mb-1">
                          <span class="label label-primary">{{
                            testCaseData.status == 1 ? "Active" : "Inactive"
                          }}</span>
                        </dd>
                      </div>
                    </dl>
                    <dl class="row mb-0">
                      <div class="col-sm-4 text-sm-right"><dt>Authorization:</dt></div>
                      <div class="col-sm-8 text-sm-left">
                        <dd class="mb-1">Basic dGVzdDp0ZXN0QDEyMzQ=</dd>
                      </div>
                    </dl>
                  </div>
                  <div class="col-lg-6" id="cluster_info">
                    <dl class="row mb-0">
                      <div class="col-sm-4 text-sm-right">
                        <dt>API Name:</dt>
                      </div>
                      <div class="col-sm-8 text-sm-left">
                        <dd class="mb-1">{{ testCaseData.api }}</dd>
                      </div>
                    </dl>
                    <dl class="row mb-0">
                      <div class="col-sm-4 text-sm-right">
                        <dt>Last Updated:</dt>
                      </div>
                      <div class="col-sm-8 text-sm-left">
                        <dd class="mb-1">{{ testCaseData.createdDate }}</dd>
                      </div>
                    </dl>
                    <dl class="row mb-0">
                      <div class="col-sm-4 text-sm-right"><dt>Version:</dt></div>
                      <div class="col-sm-8 text-sm-left">
                        <dd class="mb-1">v1.0.0</dd>
                      </div>
                    </dl>
                    <dl class="row mb-0">
                      <div class="col-sm-4 text-sm-right"><dt>Server:</dt></div>
                      <div class="col-sm-8 text-sm-left">
                        <dd class="mb-1">http://localhost:2345</dd>
                      </div>
                    </dl>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-12">
                    <dl class="row mb-0">
                      <div class="col-sm-2 text-sm-right">
                        <dt>Completed:</dt>
                      </div>
                      <div class="col-sm-10 text-sm-left">
                        <dd>
                          <div class="progress m-b-1">
                            <div
                              style="width: 60%"
                              class="progress-bar progress-bar-striped progress-bar-animated"
                            ></div>
                          </div>
                          <small>Test Case completed in <strong>60%</strong>.</small>
                        </dd>
                      </div>
                    </dl>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="vertical-timeline-block"
              v-for="(config, index) in JSON.parse(testCaseData.definition)"
              v-bind:key="index"
            >
              <div class="vertical-timeline-icon blue-bg">
                <i class="icon-status h5 font-bold m-t block">{{ config.statusCode }}</i>
              </div>

              <div class="vertical-timeline-content">
                <dl class="row mb-0">
                  <div class="col-sm-2 text-sm-right"><dt>Path:</dt></div>
                  <div class="col-sm-8 text-sm-left">
                    <dd class="mb-1">{{ config.path }}</dd>
                  </div>
                </dl>
                <dl class="row mb-0">
                  <div class="col-sm-2 text-sm-right"><dt>Content Type:</dt></div>
                  <div class="col-sm-8 text-sm-left">
                    <dd class="mb-1">{{ config.contentType }}</dd>
                  </div>
                </dl>
                <dl class="row mb-0">
                  <div class="col-sm-2 text-sm-right"><dt>Method:</dt></div>
                  <div class="col-sm-8 text-sm-left">
                    <dd class="mb-1">{{ config.method }}</dd>
                  </div>
                </dl>
                <dl class="row mb-0">
                  <div class="col-sm-2 text-sm-right"><dt>Request:</dt></div>
                  <div class="col-sm-8 text-sm-left">
                    <dd class="mb-1">{{ config.requestBody }}</dd>
                  </div>
                </dl>
                <dl class="row mb-0">
                  <div class="col-sm-2 text-sm-right"><dt>Response:</dt></div>
                  <div class="col-sm-8 text-sm-left">
                    <dd class="mb-1">{{ config.responseBody }}</dd>
                  </div>
                </dl>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import AxiosService from "../plugin/AxiosService";
import Constant from "../plugin/Constant";

export default {
  data() {
    return {
      testCaseData: {},
    };
  },
  created() {
    this.loadDataById();
  },
  methods: {
    loadDataById() {
      var self = this;
      var selectedId = this.$route.query.id;
      AxiosService.get(Constant.url.TEST_CASE_URI + Constant.SEPARATER + selectedId).then(
        (result) => {
          self.testCaseData = result;
        }
      );
    },
  },
};
</script>
<style>
.icon-status {
  font-style: normal;
  font-size: smaller;
}
</style>
